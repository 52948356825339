<template>
  <a-modal v-model="show" title="管理人员" :width="400" @ok="doSubmit" @cancel="_close" centered destroyOnClose>
    <a-form-model ref="formRef" :model="formData">
      <a-row :gutter="15">
        <a-col span="12" v-if="getOrganId() == 0">
          <a-form-model-item label="所属单位" label-position="top" prop="organId">
            <a-select v-model="formData.organId" style="width:100%" placeholder="请选择单位" @select="selecttion">
              <a-select-option :value="organ.organId" v-for="(organ, index) in organList" :key="index">{{
                organ.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="getOrganId() == 0 ? 12 : 24">
          <a-form-model-item :label="getOrganId() < 1 ? '管理人员' : ''" label-position="top">
            <selectStaff @input="e => (formData.staffId = e[0])"></selectStaff>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <a-spin size="large" fix v-if="loading" />
  </a-modal>
</template>

<script>
import request from '@/config/request';
import utils from '@/common/utils';
import apiUtil from '@/common/apiUtil';
import upImg from '@/components/common/upImg.vue';

import selectStaff from '@/components/common/select-staff.vue';
export default {
  components: { upImg, selectStaff },
  data() {
    return {
      show: false,
      loading: false,
      organList: apiUtil.getOrganList(),
      staffList: [],
      formData: {
        organId: apiUtil.getOrganId(''),
        staffId: ''
      }
    };
  },
  methods: {
    ...apiUtil,
    _show(form) {
      this.show = true;
      this.getstaffList();
      this.$nextTick(() => {
        this.$refs.formRef.resetFields();
        if (form) {
          this.formData = JSON.parse(JSON.stringify(form));
        } else {
          this.formData.organId = apiUtil.getOrganId('');
          this.formData.staffId = '';
        }
      });
    },
    _close() {
      this.$refs.formRef.resetFields();
      this.show = false;
      this.$emit('close');
    },
    doSubmit() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          utils.showSpin();
          request.post('/platform/reward/store/managers/save', this.formData).then(ret => {
            this.show = false;
            if (ret.code == 200) {
              utils.success('操作成功！').then(() => {
                this.show = false;
                this.$emit('success');
              });
            }
          });
        }
      });
    },
    getstaffList() {
      this.http
        .get('/platform/staff/scope-list', { organId: this.formData.organId })
        .then(ret => {
          console.log(ret.data);
          this.staffList = ret.data.records;
        })
        .catch(err => {
          this.loading = false;
        });
    },
    selecttion() {
      // console.log('改变了');
      this.getstaffList();
    }
  }
};
</script>

<style scoped>
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}

.avatar-uploader>.ant-upload {
  width: 128px;
  height: 128px;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
